 export const liVal = [
    {
      link: "Amazon",
      name: "Amazon",
    },
    {
      link: "ToDo",
      name: "ToDo",
    },
    {
      link: "CreateBlog",
      name: "Create Blog",
    },
    {
      link: "Blogs",
      name: "Blogs",
    },
    {
      link: "Resume",
      name: "Resume",
    },
    {
      link: "login",
      name: "Login",
    },
  ];